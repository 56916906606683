<template>
  <div class="listing--form-container">
    <div class="form--group" :class="{ 'has-error': validation.hasError('selectedPropertyType') }">
      <div class="left--col">
        <label for="select-property-type">
          <span>{{ $t('addListing.propertyType') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="property-type"
          id="select-property-type"
          v-model="selectedPropertyType"
          track-by="id"
          label="name"
          :options="propertyTypeList"
          :searchable="true"
          :allowEmpty="false"
          :close-on-select="true"
          :show-labels="true"
          :class="validation.hasError('rentType') ? 'is-invalid' : ''"
          :placeholder="$t('addListing.placeholder.propertyType')"
          select-label=""
          deselect-label=""
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('selectedPropertyType')">{{
          validation.firstError('selectedPropertyType')
        }}</span>
      </div>
    </div>

    <RegionalSection ref="regionalSection" />

    <MapSection ref="mapSection" />
    <listing-form-transition>
      <div v-show="selectedPropertyType">
        <div class="form--group" :class="{ 'has-error': validation.hasError('address') }">
          <div class="left--col">
            <label for="address-field">
              <span>{{ $t('general.address') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              v-model="address"
              :placeholder="$t('addListing.placeholder.address')"
              id="address-field"
              name="address"
              class="basic--input"
              type="text"
              rows="4"
            />
            <span class="val-error" v-if="validation.hasError('address')">{{
              validation.firstError('address')
            }}</span>
          </div>
        </div>

        <div class="form--group" :class="{ 'has-error': validation.hasError('categoryName') }">
          <div class="left--col">
            <label for="category-name-field">
              <span>{{ $t('addListing.categoryName') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input
              :placeholder="$t('addListing.placeholder.categoryName')"
              class="basic--input"
              id="category-name-field"
              name="category_name"
              type="text"
              v-model="categoryName"
            />
            <span class="val-error" v-if="validation.hasError('categoryName')">{{
              validation.firstError('categoryName')
            }}</span>
          </div>
        </div>

        <unit-numbering-section ref="unitNumberingSection" />
      </div>
    </listing-form-transition>
    <div class="bottom--container">
      <button class="btn btn-primary bottom--button" @click="next">
        {{ $t('addListing.btn.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
const RegionalSection = () => import('@/components/listing-form/sect1/regional-section.vue');
const MapSection = () => import('@/components/listing-form/sect1/map/map-section.vue');
const UnitNumberingSection = () =>
  import('@/components/listing-form/sect1/unit-numbering-section.vue');
import HelperMixin from '@/mixins/helpers';
import ListingFormTransition from '@/components/listing-form/listing-form-transition';

export default {
  mixins: [HelperMixin],
  components: {
    Multiselect,
    RegionalSection,
    UnitNumberingSection,
    MapSection,
    ListingFormTransition,
  },
  computed: {
    ...mapState({
      propertyTypeList: (state) => state.listingForm.sect1.location.propertyTypeList,
      form: (state) => state.listingForm.form,
    }),
    selectedPropertyType: {
      get() {
        return this.$store.state.listingForm.sect1.location.selectedPropertyType;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/SET_SELECTED_PROPERTY_TYPE', val);
      },
    },
    address: {
      get() {
        return this.$store.state.listingForm.sect1.location.address;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/SET_ADDRESS', this.cleanInput(val));
      },
    },
    categoryName: {
      get() {
        return this.$store.state.listingForm.sect1.location.categoryName;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/SET_CATEGORY_NAME', this.cleanInput(val));
      },
    },
    currentStep: {
      get() {
        return this.$store.state.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
    loading: {
      get() {
        return this.$store.state.listingForm.isLoading;
      },
      set(val) {
        this.$store.commit('listingForm/SET_LOADING', val);
      },
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.unitNumberingSection.validate());
      validationArray.push(this.$refs.regionalSection.validate());

      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
    async save() {
      try {
        this.loading = true;
        await this.$store.dispatch('listingForm/postData', 'save');
        // this.$store.commit('listingForm/sect1/SET_IS_TOUCHED', true);
        // eslint-disable-next-line no-empty
      } finally {
        this.loading = false;
      }
    },
    async next() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          if (this.form === 'edit') {
            await this.save();
          }
          this.scrollToTop();
          this.currentStep += 1;
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
  validators: {
    selectedPropertyType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.propertyType.required'));
    },
    address(value) {
      return Validator.value(value).maxLength(
        100,
        this.$i18n.t('errors.address.max', { maxChar: 100 }),
      );
    },
    categoryName(value) {
      return Validator.value(value).maxLength(
        30,
        this.$i18n.t('errors.categoryName.max', { maxChar: 30 }),
      );
    },
  },
};
</script>
