<template>
  <div class="transition--listing-form">
    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
      <slot />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'listingFormTransition',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    enter(element) {
      // eslint-disable-next-line
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      // eslint-disable-next-line
      const height = getComputedStyle(element).height;
      element.style.height = getComputedStyle(element).height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 300ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.transition--listing-form {
  will-change: height;
  /* transform: translateZ(0);
    backface-visibility: visible;
    perspective: 1000px; */
}
</style>
